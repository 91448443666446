import { injectModel } from 'core-web/state';
import { above } from 'core-web/util/mediaqueries';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ArticleCard from 'theme/components/article/ArticleCard';
import CampaignCard from 'theme/components/campaigns/CampaignCard';
import ProductCard from 'theme/components/product/ProductCard';
import ProductGuide from 'theme/components/productguide/ProductGuide';
import colors from 'theme/config/colors';
import styled from 'theme/libs/styled';

const Column = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Row = styled('div')`
    display: flex;
    width: 100%;
    flex-wrap: wrap;

    ${above.md} {
        flex-wrap: nowrap;

        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    ${above.lg} {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }
`;
const excludedProps = ['isWide', 'setNoMargin'];
const Content = styled('div', { shouldForwardProp: (prop) => excludedProps.indexOf(prop) === -1 })`
    width: ${({ isWide }) => (isWide ? 'calc(100%)' : 'calc(50% - 2px)')};
    margin-bottom: 4px;
    background-color: ${colors.white};

    ${above.md} {
        margin-bottom: 0px;
        width: ${({ isWide }) => (isWide ? 'calc(50% + 8px)' : '25%')};
    }
    ${above.lg} {
        width: ${({ isWide }) => (isWide ? 'calc(50% + 16px)' : '25%')};
    }

    &:not(:last-child) {
        margin-right: ${({ isWide, setNoMargin }) => (isWide || setNoMargin ? 0 : '4px')};

        ${above.md} {
            margin-right: 8px;
        }
        ${above.lg} {
            margin-right: 16px;
        }
    }
`;

const HeroModule = ({ customer, products, articles, topRow, bottomRow, isRightWide, isH1Heading }) => {
    const [allProducts, setAllProducts] = useState(null);

    useEffect(() => {
        const handlePriceLists = async (products) => {
            if (!products.length) {
                return;
            }

            const { getProductPrices, pricelistSeed } = customer;

            if (pricelistSeed) {
                const productsFromPricelist = await getProductPrices(products);

                if (productsFromPricelist?.length && Array.isArray(productsFromPricelist)) {
                    setAllProducts(productsFromPricelist);

                    return;
                }
            }

            setAllProducts(products);
        };

        handlePriceLists(products);
    }, [products, customer?.pricelistSeed]);

    const productsLength = (allProducts || products).length;
    return (
        <Column>
            <Row>
                {topRow.map((item, index) => {
                    const data = item.top_row_data;
                    const isWide = index === 0 || topRow.length < 3;

                    return (
                        <Content key={index} isWide={isWide}>
                            {data.selected_card === 'campaign' && (
                                <CampaignCard
                                    auto="format"
                                    loading={null}
                                    isHero={index === 0}
                                    isWide={isWide}
                                    isH1Heading={index === 0 && isH1Heading}
                                    data={data.campaign_data}
                                />
                            )}

                            {data.selected_card === 'product' && productsLength > 0 && (
                                <ProductCard
                                    lazyLoadImage={false}
                                    isWide={isWide}
                                    data={(allProducts || products).find(
                                        (p) => p.objectID === data.product_data.product,
                                    )}
                                    hideItems={['compare']}
                                    imageSizes={isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                    overrideImg={data.product_data}
                                />
                            )}

                            {data.selected_card === 'article' && articles.length > 0 && (
                                <ArticleCard
                                    auto="format"
                                    loading={null}
                                    isHero={index === 0}
                                    isWide={isWide}
                                    isH1Heading={index === 0 && isH1Heading}
                                    override={data.article_data}
                                    data={articles.find((a) => a.external_id === data.article_data.article)}
                                />
                            )}

                            {data.selected_card === 'product_guide' && (
                                <ProductGuide
                                    isHero={index === 0}
                                    isWide={isWide}
                                    isH1Heading={index === 0 && isH1Heading}
                                    data={data.product_guide_data}
                                />
                            )}
                        </Content>
                    );
                })}
            </Row>
            <Row>
                {bottomRow.map((item, index) => {
                    const data = item.bottom_row_data;
                    return (
                        <Content
                            key={index}
                            setNoMargin={index === 1 && (isRightWide || bottomRow.length > 3)}
                            isWide={item.isWide}
                        >
                            {data.selected_card === 'campaign' && (
                                <CampaignCard
                                    auto="format"
                                    loading={null}
                                    isWide={item.isWide}
                                    data={data.campaign_data}
                                />
                            )}

                            {data.selected_card === 'product' && productsLength > 0 && (
                                <ProductCard
                                    isWide={item.isWide}
                                    data={(allProducts || products).find(
                                        (p) => p.objectID === data.product_data.product,
                                    )}
                                    hideItems={['compare']}
                                    lazyLoadImage={false}
                                    imageSizes={item.isWide ? ['90vw', '45vw'] : ['40vw', '20vw']}
                                    overrideImg={data.product_data}
                                />
                            )}

                            {data.selected_card === 'article' && articles.length > 0 && (
                                <ArticleCard
                                    auto="format"
                                    loading={null}
                                    isWide={item.isWide}
                                    override={data.article_data}
                                    data={articles.find((a) => a.external_id === data.article_data.article)}
                                />
                            )}

                            {data.selected_card === 'product_guide' && (
                                <ProductGuide isWide={item.isWide} data={data.product_guide_data} />
                            )}
                        </Content>
                    );
                })}
            </Row>
        </Column>
    );
};

HeroModule.propTypes = {
    articles: PropTypes.array,
    bottomRow: PropTypes.array.isRequired,
    customer: PropTypes.object.isRequired,
    isH1Heading: PropTypes.bool,
    isRightWide: PropTypes.bool,
    products: PropTypes.array,
    topRow: PropTypes.array.isRequired,
};

HeroModule.defaultProps = {
    articles: [],
    isH1Heading: true,
    isRightWide: false,
    products: [],
};

export default injectModel('customer')(HeroModule);

import LoadingContainer from 'core-web/containers/LoadingContainer';
import ModuleContainer from 'core-web/containers/ModuleContainer';
import PropTypes from 'prop-types';
import React from 'react';
import HeroModule from 'theme/components/HeroModule';

const getProductIds = (content) => {
    const top = (content.top_row || [])
        .filter((item) => item.top_row_data.selected_card === 'product')
        .map((product) => product.top_row_data.product_data.product);
    const bottom = (content.bottom_row || [])
        .filter((item) => item.bottom_row_data.selected_card === 'product')
        .map((product) => product.bottom_row_data.product_data.product);

    return [...top, ...bottom];
};

const getArticleIds = (content) => {
    const top = (content.top_row || [])
        .filter((item) => item.top_row_data.selected_card === 'article')
        .map((article) => article.top_row_data.article_data.article);
    const bottom = (content.bottom_row || [])
        .filter((item) => item.bottom_row_data.selected_card === 'article')
        .map((article) => article.bottom_row_data.article_data.article);

    return [...top, ...bottom];
};

const Loading = (props) => <LoadingContainer {...props} success={(props) => <HeroModule {...props} />} />;

const Hero = ({ content, pageHasHeading }) => {
    const productIds = getProductIds(content);
    const articleIds = getArticleIds(content);

    const isRightWide = content.layout_selections === 'right_wide';
    const topRow = content.top_row;
    const bottomRow = (content.bottom_row || []).map((item, index, arr) => {
        if (
            arr.length === 2 ||
            (!isRightWide && index === 0 && arr.length < 4) ||
            (isRightWide && index === 2 && arr.length < 4)
        ) {
            item.isWide = true;
        }

        return item;
    });

    return topRow && bottomRow ? (
        <ModuleContainer
            productIds={productIds}
            productAttributes={[
                'name',
                'image',
                'subHeader',
                'url',
                'flags',
                'type',
                'pricing',
                'objectID',
                'onHand',
                'manufacturer',
                'parentProductId',
                'parametrics',
            ]}
            articleIds={articleIds}
            render={Loading}
            renderProps={{ topRow, bottomRow, isRightWide, isH1Heading: !pageHasHeading }}
        />
    ) : null;
};

Hero.propTypes = {
    content: PropTypes.object.isRequired,
    pageHasHeading: PropTypes.bool,
};

Hero.defaultProps = {
    pageHasHeading: false,
};

export default Hero;

import React from 'react';
import PropTypes from 'prop-types';
import { ContentModules } from 'core-web/ContentModules';
import { above } from 'core-web/util/mediaqueries';
import styled from 'theme/libs/styled';
import get from 'core-web/util/get';
import Hero from 'theme/content-modules/Hero';
import { injectModel } from 'core-web/state';

const HeroWrapper = styled('div')`
    &.noHero {
        height: 0;
    }
    &.heroActive {
        min-height: 1365px;
        ${above.md} {
            min-height: 647px;
        }

        ${above.lg} {
            min-height: 657px;
        }
    }
`;
const Div = styled('div')`
    padding: 0 0 48px;

    ${above.md} {
        padding: 8px 8px 48px;
    }

    ${above.lg} {
        padding: 16px 16px 56px;
    }
`;

const FrontPage = ({ application, data }) => {
    const heroContent = get(data, 'hero_content.data');
    const pageContent = data.page_content;

    // Override settings
    const overrides = get(application, 'config.options.overrides', {});
    const overrideActive = overrides.elements?.indexOf('frontpage') !== -1 && overrides.frontpage?.active;
    const heroContentOverride = get(overrides, 'frontpage.hero_content.data');
    const pageContentOverride = get(overrides, 'frontpage.page_content');

    // // Set page data
    const heroData = overrideActive && heroContentOverride ? heroContentOverride : heroContent;

    const pageContentData = overrideActive && pageContentOverride ? pageContentOverride : pageContent;

    const pageHasHeading = pageContent?.some((item) => item.layout === 'heading');

    return (
        <Div>
            <HeroWrapper className={heroContent ? 'heroActive' : 'noHero'}>
                {heroContent && <Hero content={heroData} pageHasHeading={pageHasHeading} />}
            </HeroWrapper>
            {pageContent && <ContentModules modules={pageContentData} />}
        </Div>
    );
};

FrontPage.propTypes = {
    application: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
};

export default injectModel('application')(FrontPage);
